<template>
  <van-skeleton v-if="loading" :row="3" />
  <div v-else class="page">
    <component-to-re-render :key="componentKey" />
    <div id="current_name_ci_list" v-if="current_name.ci_list.length" >
      <span  v-if="use_way.cixing!=='全部'" >{{use_way.cixing}}: </span>
      <span v-for="ci in current_name.ci_list" :key="ci">{{ci}}</span>
    </div>

    <div id='current_name'>
      <router-link id='current_name_word' :class="'word_' + current_name.color" :to="{ name: 'name_detail', query: { name: current_name.word }}"  >{{ current_name.word }}</router-link>
      <span v-if="current_name.word" id="reverse_name" @click="reverse_name" >颠倒</span>
    </div>

    <div class="foot" >
      <router-link :to="{ name: 'name_detail', query: { name: current_name.word }}"  ><button class="black w2">看详情</button></router-link>
      <button :class="current_name.mark? 'w2 background_' + current_name.color :'white w2'" :disabled="disabled" @click="mark_name_or_not" >{{ current_name.mark ? '取消收藏' : '先收藏'  }}</button>
    </div>
  </div>
</template>

<script>

import Utils from '@/utils/utils'
import axios from 'axios'
import router from '@/router/index.js'
import { Toast } from 'vant'

export default {
  name: 'NameEasy',
  props: {
    xing: String,
    fit_word: String,
    current_word: String,
    use_way: Map
  },
  data () {
    return {
      current_name: { ci_list: [] },
      page: 1,
      loading: true,
      loading2: false,
      disabled: false
    }
  },
  mounted () {
    this.get_current_name()
  },
  methods: {
    get_current_name () {
      const nameStr = this.use_way.fit_place === '在前' ? (this.xing + this.fit_word + this.current_word) : (this.xing + this.current_word + this.fit_word)
      this.get_name_detail_and_cixing(nameStr, this.current_word)
    },
    reverse_name (e) {
      const nameStr = this.current_name.word
      this.get_name_detail_and_cixing(nameStr[0] + nameStr[2] + nameStr[1], this.current_word)
    },
    get_name_detail_and_cixing (name, word) {
      const data = { name: name, word: word, cixing: this.use_way.cixing }
      axios.post('/name_detail_and_cixing/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.current_name = res.data.data.name_info
          this.loading = false
        })
    },
    mark_name_or_not (e) {
      Utils.show_loading()
      this.disabled = true
      const data = { name: this.current_name.word }
      axios.post('/mark_name_or_not/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.disabled = false
          this.current_name.mark = res.data.data.mark
          Toast.clear()
        })
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    go_to_name_detail (e) {
      const nameStr = e.currentTarget.dataset.value
      router.push({ name: 'name_detail', query: { name: nameStr } })
    }
  }
}
</script>
<style scoped>

#current_name_ci_list{
  position:absolute ;
  text-align: center;
  margin-top:  1rem;
  height:  1rem;
  width: 10rem;
}
#current_name_ci_list span{
  margin: 0.30rem  0.2rem;
  color: lightgray;
}

#current_name{
  padding-top: 1.6rem;
  text-align: center;
  height: 2.8rem;
}
#current_name_word{
  font-size:  1rem;
  line-height: 2.8rem;

}

#reverse_name{
  position: absolute;
  right:1.6rem;
  margin-top: 0.72rem;
  color: orange;
  background: rgba( 255,165,0,0.1);
  border-radius:  0.6rem;
  width: 1.20rem;
  height:  1.20rem;

  line-height: 1.20rem;
  text-align: center;
  font-size:  0.36rem;
}
a.word_color_1{
  color:#FFB6C1;
}
a.word_color_2{
   color: #DBBFD1;
}
a.word_color_3{
   color: #C1C5DD;
}
a.word_color_4{
   color: #A6CBE9;
}
a.word_color_5{
   color: #81D3F8;
}
</style>
