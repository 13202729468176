<template>
  <van-skeleton v-if="loading" row="6" />
  <div v-else class="page">
    <div class="head">
      <div class="title">
        <p v-if="use_way.fit_place=='在前'">
          <span @click="pull_up" data-value="detail" >{{xing}}{{info.word}}◇</span>
          <span @click="pull_up" data-value="detail"  class="b">挑选搭配字</span>
        </p>
        <p v-else>
          <span @click="pull_up" data-value="detail"  class="b">{{xing}}◇{{info.word}}</span>
          <span @click="pull_up" data-value="detail"  class="b">挑选搭配字</span>
        </p>
      </div>
      <div class="about">
        <div class="l1">
          <p  class="black_tag" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >固定字{{use_way.fit_place}} ▾</p>
          <p  class="black_tag" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" > 词性:{{use_way.cixing}} ▾ </p>
          <p  class="black_tag" data-value="请到应用商店下载花样起名APP，使用体验更佳。"  @click="want_alert" >{{use_way.paixu}} ▾</p>
        </div>
      </div>
    </div>

    <van-tabs v-model:active="which_page" @change="click_tabs" color="#212b36" sticky  >

      <van-tab v-for="( head, k ) in head_list" :key="k" :title="head.type +'('+head.nums +')'">
        <van-skeleton v-if="loading2" :row="3" />
        <div  v-else>
          <button  v-if="!can_see_more" class="to_be_vip" data-value="name_fit|" @click="xml_go_to_goods_index">会员可灵活使用 独家文字搭配</button>
          <ul class="fit">
            <div v-for="(item, index) in head.list"  :key="index"  >
              <p v-if=" index % 50 === 0 && index>10"  class="sign">  {{ index }} + ▼</p>
              <li class='one_zi' :data-value="item.word" :class = "'word_' + item.color" @click="pull_up_name" >{{item.word}}</li>
            </div>
          </ul>
          <text v-if="head.page===-1" class="end">-- End --</text>
          <van-pagination v-model="head.page" :page-count="Math.ceil(head.nums/50 )" mode="simple" @change="fanye" />
          <p v-if="can_see_more" class="warn_tips" data-value="bind_tips" @click="xml_go_to">使用微信小程序，体验更佳。查看如何绑定</p>
        </div>
      </van-tab>
    </van-tabs>
    <ul id="last_right_c" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>

    <!--  zi detail --->
    <van-popup v-model:show="show.detail"  round position="bottom"  :style="{ height: '90%' }"  @close='on_close'>
      <DetailZi :info="info" />
    </van-popup>

    <!--  名字预览 --->
    <van-popup v-model:show="show.name"  round position="bottom" :style="{ height: '7.2rem' }" @close='on_close'>
      <NameEasy :xing=xing :fit_word=info.word :current_word=current_word :use_way=use_way  v-if="renderComponent" />
    </van-popup>
  </div>
</template>

<script>

import axios from 'axios'
import Utils from '@/utils/utils'
import NameEasy from '@/components/other/NameEasy'
import DetailZi from '@/components/other/DetailZi'

export default {
  name: 'name_fit',
  components: {
    DetailZi, NameEasy
  },
  data () {
    return {
      niming: false,
      loading: true,
      loading1: false,
      loading2: false,
      renderComponent: false,
      can_see_more: false,
      show: { name: false, detail: false },
      info: { mean: {}, same_diao_list: [], same_yin_list: [] },
      xing: '',
      current_word: '',
      head_list: [],
      which_page: 0,
      cixing_list: [],
      // paixu_list: [{ name: '默认排序', checked: true }, { name: '笔画排序' }, { name: '偏男字排前' }, { name: '偏女字排前' }],
      // fit_place_list: [{ name: '在前', checked: true }, { name: '在后' }],
      use_way: { fit_place: '在前', paixu: '默认排序', cixing: '全部' }
    }
  },
  mounted () {
    this.fit_index()
  },
  methods: {
    fit_index () {
      const data = { page: 1, word: this.$route.query.word, guide_use_way: this.$route.query.use_way ? JSON.parse(this.$route.query.use_way) : {} }
      axios.post('/fit_index/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (this.$route.query.use_way) {
            this.use_way = JSON.parse(this.$route.query.use_way)
          } else {
            this.use_way = res.data.data.use_way
          }
          this.xing = this.$route.query.xing
          this.loading = false
          this.info = res.data.data.info
          this.can_see_more = res.data.data.can_see_more
          this.head_list = res.data.data.head_list
          this.cixing_list = res.data.data.cixing_list

          this.get_fit_list_fenye(1)
          Utils.alert_tip_list('fit_index', [{ tag: 'one', desc: '您可以点击任意字，系统将自动组合成一个可供预览的名字。' }, { tag: 'two', desc: '点击菜单栏可以按词性、笔画、性别等 筛选排序搭配字。' }])
        })
        .catch(err => {
          alert(err)
        })
    },

    get_fit_list_fenye (page) {
      const data = { page: page, use_way: this.use_way, fit_type: this.head_list[this.which_page].type, xing: this.xing, word: this.info.word }
      this.loading2 = true
      this.head_list[this.which_page].list = []
      axios.post('/fit_list/', data)
        .then(res => {
          this.loading2 = false
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          if (res.data.data.need_vip) {
            Utils.show_ask_goods({ page: 'name_fit', tag: 'fit' })
            return
          }
          this.head_list[this.which_page].list = res.data.data.list
        })
        .catch(err => {
          alert(err)
        })
    },
    click_tabs (e) {
      this.which_page = e
      if (!this.head_list[this.which_page].list.length) {
        this.get_fit_list_fenye(1)
      }
      if (e === 1) {
        Utils.is_need_alert_tips('fit_fanyong', '泛用字为以往名字中常出现的字，如梓、伟等，避免使用泛用字可降低重名概率')
      } else if (e === 2) {
        Utils.is_need_alert_tips('fit_other', '此处多为不雅字、生僻字、高频但很少用来做名字的字，请谨慎使用')
      }
    },
    pull_up_name (e) {
      this.current_word = e.currentTarget.dataset.value
      this.show.name = true
      this.renderComponent = true
    },

    want_alert (e) {
      Utils.alert({ content: e.currentTarget.dataset.value })
    },
    fanye (e) {
      this.get_fit_list_fenye(e)
    },
    pull_up (e) {
      this.show[e.currentTarget.dataset.value] = true
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to (e) {
      Utils.go_to(e)
    },
    on_close (e) {
      this.renderComponent = false
      this.show = { name: false, detail: false }
    }
  }
}
</script>

<style scoped>
.fit {
  text-align: left;
}
.fit div{
  display: inline-block;
  /*padding-bottom: 0.4rem;*/

}
.one_zi{
  display: inline-block;
  padding:  0.24rem  0.24rem;
  margin:  0.3rem  0.12rem .3rem 0.75rem;
  text-align: center;
  font-size:  0.50rem;
  border-bottom:  0.02rem dashed #ccc;
}

</style>
